.loading__container {
    background-color: black;
    opacity: 0.5;
    position: absolute;
    z-index: 99999;
    min-height: 100%;
    min-width: 100%;

    img {
        max-height: 350px;
    }

    .parent {
        position: relative;
    }
    .child {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
