.searchbar__ {
    &container {
        margin-bottom: 20px;
    }

    &text {
        margin-top: 10;
    }

    &button {
        float: right;
        margin-top: 6px;
    }

    &fields {
        display: grid;
        grid-template-columns: 4fr 2fr 1fr;
        align-items: center;
        > .search__element {
            margin: 10px;
            min-width: 60px;
        }
    }
}
