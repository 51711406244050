.MuiListItemButton-root {
    background-color: $lightgray;
}

.active {
    .MuiTouchRipple-root {
        background-color: $mediumOrange;
    }
    .MuiTypography-root {
        color: $paper;
        position: relative;
        z-index: 9999;
    }
    .MuiListItemIcon-root {
        color: $paper;
        position: relative;
        z-index: 9999;
    }
}

.MuiChip-root {
    &:hover {
        background-color: $lightgray;
        svg {
            fill: $paper;
        }
    }
}

.MuiAvatar-root {
    background-color: $lightgray;

    &:hover {
        background-color: $mediumOrange;
        svg {
            fill: $paper;
        }
    }
}
